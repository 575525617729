import floodZone from "./flood-zone-map.png";
import "./App.css";

const WidgetSmall = () => {
  return (
    <div className="fh-widget-container-smaller">
      <div className="understanding-flood-risk-smaller">
        UNDERSTAND YOUR FLOOD RISK
      </div>
      <div className="flood-img-button-container">
        <img
          src={floodZone}
          className="flood-zone-img-smaller"
          alt="flood-zone"
        />
        <a
          href="https://www.floodhelpny.org/"
          target="_blank"
          className="link-to-flood-help-smaller"
        >
          Learn more at FloodHelpNY.org
        </a>
      </div>
    </div>
  );
};

export default WidgetSmall;
