import floodZone from "./flood-zone-map.png";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

function WidgetLarge() {
  return (
    <div className="fh-widget-container">
      <div className="understanding-flood-risk">UNDERSTAND YOUR FLOOD RISK</div>
      <div className="flood-zone-img-container">
        <img src={floodZone} className="flood-zone-img" alt="flood-zone" />
      </div>
      <div className="link-to-flood-help-container">
        <a
          href="https://www.floodhelpny.org/"
          target="_blank"
          className="link-to-flood-help"
        >
          Learn more at FloodHelpNY.org
        </a>
      </div>
    </div>
  );
}

export default WidgetLarge;
