import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import WidgetLarge from "./WidgetLarge";
import WidgetSmall from "./WidgetSmall";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/292" element={<WidgetSmall />} />
          <Route path="/372" element={<WidgetLarge />} />
          <Route path="/" element={<WidgetSmall />} />
          <Route path="*" element={<WidgetSmall />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
